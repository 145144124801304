// import "./import/modules";
// import domI18n from "./import/libs/dom-i18n.min";
import { Translate } from "./import/translate";

document.addEventListener("DOMContentLoaded", () => {
  window.addEventListener("scroll", () => {
    document.documentElement.style.setProperty("--scroll-y", `${window.scrollY}px`);
  });

  const translate = new Translate();
  const lang = localStorage.getItem('lang') || 'en';
  const attr = 'data-tag';

  translate.init(attr, lang);
  translate.process();

  // Translater
  // const i18n = domI18n({
  //   selector: "[data-translate]",
  //   separator: " // ",
  //   languages: ["en", "ru"],
  //   defaultLanguage: "en",
  // });

  // Language change
  const langBtn = document.querySelector(".lang-btn");
  const langLinkElements = [].slice.call(document.querySelectorAll(".lang-link"));

  langBtn.addEventListener("click", () => {
    document.querySelector(".js-field").classList.toggle("active");
  });

  const langLinks = langLinkElements.map((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();

      let lang = e.target.getAttribute("data-lang");
      let text = document.querySelector(".js-lang-text");

      text.innerHTML = lang;
      // i18n.changeLanguage(lang);
      localStorage.setItem('lang', lang) 
      translate.init(attr, lang);
      translate.process();
    });
  });

  // Menu
  const { body } = document;
  const burgerBtn = document.querySelector(".burger-btn");
  // const mobileNav = document.querySelector(".header__mobile-nav");

  // burgerBtn.addEventListener("click", () => {
  //   mobileNav.classList.toggle("active");
  //   body.classList.toggle("active-mobile");

  //   body.classList.contains("active-mobile")
  //     ? ((body.style.position = "fixed"), (body.style.top = "-".concat(window.scrollY, "px")))
  //     : ((body.style.position = ""), (body.style.top = ""));
  // });
  // document.addEventListener("mouseup", (e) => {
  //   const { target } = e;

  //   if (!mobileNav.contains(target) || target.classList.contains("js-burger-svg")) {
  //     body.classList.remove("active-mobile");
  //     body.style.position = "";
  //     body.style.top = "";
  //     mobileNav.classList.remove("active");
  //   }
  // });

  // $(document).mouseup(function (e) {
  //   let mobileMenu = $(".header__mobile-nav");
  //   if (!mobileMenu.is(e.target) && mobileMenu.has(e.target).length === 0) {
  //     $("body").removeClass("active-mobile");
  //     (document.body.style.position = ""), (document.body.style.top = "");
  //     mobileMenu.removeClass("active");
  //   }
  //   let lang = $(".lang");
  //   if (!lang.is(e.target) && lang.has(e.target).length === 0) {
  //     $(".field").removeClass("active");
  //   }
  // });

  const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };
  const getCookie = (cookieName) => {
    let name = cookieName + "=";
    let all = document.cookie.split(";");
    for (let i = 0; i < all.length; i++) {
      let c = all[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const deleteCookie = (cookieName) => {
    setCookie(cookieName, "", -1);
  };

  const cookieBtn = document.querySelector(".footer-close");
  const cookieBox = document.querySelector(".footer__top");
  const statusCookie = getCookie("user-ckecked");

  cookieBtn.addEventListener("click", () => {
    cookieBox.style.display = "none";
    setCookie("user-ckecked", "on", 36);
  });
  if (statusCookie === "on") {
    cookieBox.style.display = "none";
  }

  // $('.footer-close').on('click', function () {
  // 	$('.footer__top').fadeOut(400);
  // 	setCookie("user-ckecked", 'on', 36);
  // });
});
