export class Translate {
  init(attr, lang) {
    this.attr = attr;
    this.lang = lang;
  }

  process() {
    let xrhFile = new XMLHttpRequest();
    
    xrhFile.open("GET", "/landing/res/" + this.lang + ".json", false);
    xrhFile.onreadystatechange = () => {
      if (xrhFile.readyState === 4) {
        if (xrhFile.status === 200 || xrhFile.status == 0) {
          const langObject = JSON.parse(xrhFile.responseText);
          const allDomEls = document.getElementsByTagName("*");
          for (let i = 0; i < allDomEls.length; i++) {
            const el = allDomEls[i];
            const key = el.getAttribute(this.attr);

            if (key != null) {
              el.innerHTML = langObject[key];
            }
          }
        }
      }
    };
    xrhFile.send();
  }
}
